/* eslint-disable */
import axios from 'axios';
import ConfigService from './ConfigService'  
var bcrypt = require('bcryptjs')

class CrmService {

//*****************
// Login user 
//*****************
static async userLogin(username, password) {
//   User database
  const users = [
// Stephen Rogers
    { login: "sarogers", firstName: "Stephen", lastName: "Rogers", userUid:"2d5daf18-bb5d-4f42-81da-43635562b758",  customerUid:"1f9b180c-b561-11e9-a580-193eebdf9d76", organizationName: "The Rogers", password:"$2b$10$A5akJ/JtGRcNL4IEFLb2.eIojYER/HwQ2bUEuneVL/OxQ6pF6fi5m", siteUid: "676ea531-30dc-4ab2-8f7a-0b9945bd50b3", siteName: "Nash Woods" }, // Flamingo123$
// Darren Lanz
    { login: "dlanz", firstName: "Darren", lastName: "Lanz", userUid: "75a84463-6abd-4138-ac1f-937319e9232f",  customerUid:"dbd1e70c-b699-4e01-a57b-f833205346a9", organizationName: "Lanz Enterprises", password:"$2b$10$wf50XW25I.YcAA94Dk3XiuvK/vD0ivld8nqau35ZhSVaKOny3HNB6", siteUid:"700bc214-b561-11e9-a580-193eebdf9d76", siteName: "Lanz Ridge" }, // Kodi$
// Daniel Bingham
    { login: "dbingham", firstName: "Daniel", lastName: "Bingham", userUid: "e17d7fab-3524-4ec2-b90e-cbd2266f1e3a",  customerUid:"7e10f360-2ebd-4efc-9c20-77c1b29a3c69", organizationName: "Bingham Household", password:"$2b$10$n70LEvBJ.gmjLvH6r53wme/I5dtD1l8F4mSkpvp0Vr2eGFeyx5Q.u", siteUid:"a09c4d81-1022-411a-8794-d63f139bca6a", siteName: "Bingham View" }, // Biofish$
// Mary Beth Rogers
    { login: "mbrogers", firstName: "Mary Beth", lastName: "Rogers", userUid: "f794aa38-5f88-4bf8-9923-30d14cb3ea4d",  customerUid:"1f9b180c-b561-11e9-a580-193eebdf9d76", organizationName: "The Rogers", password:"$2b$10$L0.4H3LwpKGDxhMGqdGGjutrlTe7nUGqUgtJrxmcnXcFd8C2bcSPS", siteUid: "676ea531-30dc-4ab2-8f7a-0b9945bd50b3", siteName: "Nash Woods" }, // Melody59$
// Chris Balogh
    { login: "chrisbalogh", firstName: "Chris", lastName: "Balogh", userUid: "e8242a5e-9407-4092-8252-077e55558852",  customerUid:"16970777-79d3-4f3a-9cdb-57b8c7d1fbd0", organizationName: "The Baloghs", password:"$2b$10$SOP54UJ5ekVXlS7M/XXsROYX7aTvaKUaGSgH2kso8xcf.3nGuzHEy", siteUid:"91f8512a-188e-4629-b428-6457ab867dbe", siteName: "Balogh Acres" }, // 3Kidz$
// Cara Balogh
    { login: "carabalogh", firstName: "Cara", lastName: "Balogh", userUid: "568109cb-69bd-421d-8e1c-f701433b6f83",  customerUid:"16970777-79d3-4f3a-9cdb-57b8c7d1fbd0", organizationName: "The Baloghs", password:"$2b$10$fiIvPSjyLciYTOEN.tma5.2NSf/9Jkz79FhGIeMPrlG6p1mqi7zae", siteUid:"91f8512a-188e-4629-b428-6457ab867dbe", siteName: "Balogh Acres" }, // 3Kidz$   
  ]
  // Confirm login is registered
  const user = users.find(o => o.login === username)
  if (user == null) {
    console.log("Login not found")
    return null  // fail (user login not found)
  }
  // user is registered, so check the password
  try { 
    if (await bcrypt.compare(password, user.password)) {
    // password is correct, so... 

    } else {
    // password is wrong, so... 
    console.log("Password is incorrect")
    return -1 // fail (password incorrect)
    }
    //***** Successful creds
    console.log("Successful login")
    return user

  } catch (err) {
    this.error = err.message
    console.log('created: err= ', this.error)
  }
}

//*****************
// Return all customers available to the logged in user
//*****************
static customerSelectAll (loggedin, current_customer) {
    // const config = await this.getConfigFile()
    // if (config.useProxy) {
    //   return axios
    //     .get("api/proxy/customers")
    //     .then(response => response.data)
    //     .catch(error => console.error(error));
    if (!loggedin.state){
      return [] }
    if (loggedin.userUid == '2d5daf18-bb5d-4f42-81da-43635562b758')
      { // Gorge Logic / Stephen / Admin so return all customers
        const cust = [
            {
              organizationName: "The Rogers", 
              customerUid: "1f9b180c-b561-11e9-a580-193eebdf9d76",
            },
            {
              organizationName: "Lanz Enterprises",
              customerUid: "dbd1e70c-b699-4e01-a57b-f833205346a9",
            },
            {
              organizationName: "Bingham Household",
              customerUid: "7e10f360-2ebd-4efc-9c20-77c1b29a3c69",
            },
            {
              organizationName: "The Baloghs", 
              customerUid: "16970777-79d3-4f3a-9cdb-57b8c7d1fbd0",
            },
        ]
        // debugger
        return cust
      }
    // Not Admin so return the logged in customer only
      return [current_customer]
}

// //*****************
// // Return all sites available to the logged in user
// //*****************
// static async siteSelectAll () {
//     const config = await this.getConfigFile()
//     if (config.useProxy) {
//       return axios
//         .get("api/proxy/sites")
//         .then(response => response.data)
//         .catch(error => console.error(error));
//     } else {
        
//     }
// }

//*****************
// Return site from owning customerUid
//*****************
static siteSelectByCustomerUid(customerUid) {
  let sites = []
  // const config = await this.getConfigFile()
  // if (config.useProxy) {
  //   return axios
  //     .get("api/proxy/customers/" + customerUid + "/sites")
  //     .then(response => response.data)
  //     .catch(error => console.error(error));
  // } else {
    switch(customerUid) { 
      case "1f9b180c-b561-11e9-a580-193eebdf9d76": {
        sites.push({
          organizationName: "The Rogers",
          siteName: "Nash Woods",   
          siteUid: "676ea531-30dc-4ab2-8f7a-0b9945bd50b3" })
          break;
      }
      case "dbd1e70c-b699-4e01-a57b-f833205346a9": {
        sites.push({
          organizationName: "Lanz Enterprises",
          siteName: "Lanz Ridge",   
          siteUid: "700bc214-b561-11e9-a580-193eebdf9d76" })
          break;
      }
      case "7e10f360-2ebd-4efc-9c20-77c1b29a3c69": {
        sites.push({
          organizationName: "Bingham Home",
          siteName: "Bingham View",   
          siteUid: "a09c4d81-1022-411a-8794-d63f139bca6a" })
          break;
      }
      case "16970777-79d3-4f3a-9cdb-57b8c7d1fbd0": {
        sites.push({
          organizationName: "The Baloghs",
          siteName: "Balogh Acres",   
          siteUid: "91f8512a-188e-4629-b428-6457ab867dbe" })
          break;
      }
    }
      // debugger
      return sites
}

static async getConfigFile () {
    //*****************
    //******* Get Config File Settings
    //*****************
        try {
        const config = await ConfigService.settingsGetOverview()
        // console.log('CrmService.GetConfigFile: config = ', config)
        return config
        } catch (err) {
        this.error = err.message
        console.log('CrmSourceService.GetConfigFile: err= ', this.error)
        }
    }
}
export default CrmService;